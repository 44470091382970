import { combineReducers } from "@reduxjs/toolkit";

import sessionReducer from "./session/reducer";
import earningsReducer from "./earnings/reducer";
import bankingReducer from "./banking/reducer";
import capitalOffersReducer from "./offers/reducer";
import uploadsReducer from "./uploads/uploadSlice";
import financialsReducer from "./financials/financialsSlice";
import cashActivityReducer from "./financials/cashActivitySlice";
import gameMetricsReducer from "./game-metrics/gameMetricsSlice";
import paymentsReducer from "./payments/paymentsSlice";

// Webshops
import webshopsReducer from "./webshops/webshopsSlice";
import catalogReducer from "./webshops/catalog/catalogSlice";
import seriesReducer from "./webshops/catalog/series/seriesSlice";
import developerReducer from "./webshops/developer/developerSlice";
import associationsReducer from "./webshops/associations/associationsSlice";
import builderReducer from "./webshops/builder/builderSlice";
import sectionsReducer from "./webshops/builder/sectionsSlice";
import linksReducer from "./webshops/builder/linksSlice";
import settingsReducer from "./webshops/webshop/settings/settingsSlice";
import authMethodsReducer from "./webshops/webshop/settings/auth-methods/authMethodsSlice";
import customDomainReducer from "./webshops/webshop/settings/custom-domain/customDomainSlice";
import typographySlice from "./webshops/builder/typographySlice";
import integrationsReducer from "./webshops/webshop/settings/integration/integrationsSlice";

// Integrations
import baseReducer from "./integrations/base/reducer";
//to replace baseReducer in the future
import integrationsConnectionReducer from "./integrations/base/baseSlice";
import suggestionsReducer from "./integrations/suggestions/suggestionsSlice";
import manualConnectionReducer from "./integrations/base/manualSlice";
import adjustReducer from "./integrations/adjust/reducer";
import advancesReducer from "./integrations/advances/reducer";
import appsFlyerReducer from "./integrations/appsFlyer/reducer";
import appStoreReducer from "./integrations/appStore/reducer";
import googlePlayReducer from "./integrations/googlePlay/reducer";
import plaidReducer from "./integrations/plaid/reducer";
import prePlaidModalReducer from "./integrations/plaid/prePlaidModalSlice";
import acknowledgeBankModalReducer from "./integrations/bank/acknowledgeBankModalSlice";
import firebaseReducer from "./integrations/firebase/firebaseSlice";
import steamReducer from "./integrations/steam/steamSlice";
import adNetworksReducer from "./integrations/ad-networks/adNetworksSlice";
import singularReducer from "./integrations/singular/singularSlice";
import financeReducer from "./finance/financeSlice";
import uaSpendReducer from "./ua-spend/uaSpendSlice";

const rootReducer = combineReducers({
  session: sessionReducer,
  earnings: earningsReducer,
  banking: bankingReducer,
  offers: capitalOffersReducer,
  uploads: uploadsReducer,
  financials: financialsReducer,
  cashActivity: cashActivityReducer,
  gameMetrics: gameMetricsReducer,
  payments: paymentsReducer,
  finance: financeReducer,
  uaSpend: uaSpendReducer,
  integrations: combineReducers({
    base: baseReducer,
    // to replace baseReducer in the future
    integrationsConnections: integrationsConnectionReducer,
    suggestions: suggestionsReducer,
    acknowledgeBankModal: acknowledgeBankModalReducer,
    manualConnection: manualConnectionReducer,
    prePlaidModal: prePlaidModalReducer,
    advances: advancesReducer,
    plaid: plaidReducer,
    adjust: adjustReducer,
    appsflyer: appsFlyerReducer,
    appstore: appStoreReducer,
    googleplay: googlePlayReducer,
    firebase: firebaseReducer,
    singular: singularReducer,
    steam: steamReducer,
    adNetworks: adNetworksReducer,
  }),
  webshops: combineReducers({
    webshops: webshopsReducer,
    catalog: catalogReducer,
    series: seriesReducer,
    associations: associationsReducer,
    builder: builderReducer,
    sections: sectionsReducer,
    links: linksReducer,
    typography: typographySlice,
    settings: combineReducers({
      settings: settingsReducer,
      authMethods: authMethodsReducer,
      customDomain: customDomainReducer,
      integrations: integrationsReducer,
    }),
    developer: developerReducer,
  }),
});

export default rootReducer;
