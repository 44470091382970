import { copy } from "@utils/general";

import { BUILDER_SECTION_TYPES } from "@src/consts/webshops/builder";
export const SECTION_TYPE = BUILDER_SECTION_TYPES.FEATURED_ITEM;

export const generatePayloads = ({ data, updates }) => {
  const combinedSectionData = {
    type: SECTION_TYPE,
    ...copy(data),
    ...copy(updates),
  };

  if (combinedSectionData.series) {
    combinedSectionData.itemId = "";
    combinedSectionData.seriesId = combinedSectionData.series.id;
  } else {
    combinedSectionData.seriesId = "";
    combinedSectionData.itemId = combinedSectionData.item.id;
  }

  // Other info in the section will cause errors
  // like "id" and "items"/"series" so we need to remove them
  const requiredDefaults = {
    type: SECTION_TYPE,
    order: 0,
    title: "",
    itemId: "",
    seriesId: "",
  };

  const request = {};
  Object.keys(requiredDefaults).forEach((key) => {
    if (Object.hasOwn(combinedSectionData, key)) {
      request[key] = combinedSectionData[key];
    } else {
      request[key] = requiredDefaults[key];
    }
  });

  return copy({
    request,
    response: combinedSectionData,
  });
};

export const getButtonLabel = ({ item = null, series = null }) => {
  const labels = {
    itemButtonLabel: "Item",
    seriesButtonLabel: "Series",
  };

  if (!series && item) {
    labels.itemButtonLabel = "Change item";
    labels.seriesButtonLabel = "Change to series";
  } else if (series) {
    labels.itemButtonLabel = "Change to item";
    labels.seriesButtonLabel = "Change series";
  }

  return labels;
};
