import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults } from "@utils/redux";

import TypographyService from "./typographyApi";

import { errorWrapper } from "@redux/http";
import { TYPOGRAPHY_FONT_TYPES, TYPOGRAPHY_FONT_VARIANTS } from "@src/consts/webshops/builder";
import listenerMiddleware from "@redux/listenerMiddleware";
import { updateStorefrontTypography } from "./builderSlice";

export const typographyThunks = {
  getFonts: createAsyncThunk("webshops/getFonts", async ({ webshopId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await TypographyService.getFonts(webshopId);
      return res.data;
    }, rejectWithValue);
  }),
  updateSelectedFont: createAsyncThunk(
    "webshops/updateSelectedFont",
    async ({ webshopId, data }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const res = await TypographyService.updateSelectedFont(webshopId, data);
        return res.data;
      }, rejectWithValue);
    },
  ),
  resetSelectedFont: createAsyncThunk("webshops/resetSelectedFont", async ({ webshopId }, { rejectWithValue }) => {
    return errorWrapper(async () => {
      const res = await TypographyService.resetSelectedFont(webshopId);
      return res.data;
    }, rejectWithValue);
  }),
  addCustomFont: createAsyncThunk("webshops/addCustomFont", async ({ webshopId, data }, { rejectWithValue }) => {
    try {
      const res = await TypographyService.addCustomFont(webshopId, data);
      return res.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }),
  addCustomFontBulk: createAsyncThunk(
    "webshops/addCustomFontBulk",
    async ({ webshopId, data }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const res = await TypographyService.addCustomFontBulk(webshopId, data);
        return res.data;
      }, rejectWithValue);
    },
  ),
  removeCustomFont: createAsyncThunk(
    "webshops/removeCustomFont",
    async ({ webshopId, fontId }, { rejectWithValue }) => {
      return errorWrapper(async () => {
        const res = await TypographyService.removeCustomFont(webshopId, fontId);
        return res.data;
      }, rejectWithValue);
    },
  ),
};

export const typographySlice = createSlice({
  name: "sections",
  initialState: {
    availableFonts: [],
    selectedFonts: {
      headlineId: null,
      regularId: null,
    },
    customFonts: {
      selected: null,
      recentlyAdded: [],
      uploaded: [],
    },
    fontToRemove: null,
    getFonts: {
      requestStatus: setStatusDefaults(),
    },
    updateSelectedFont: {
      requestStatus: setStatusDefaults(),
    },
    resetSelectedFont: {
      requestStatus: setStatusDefaults(),
    },
    addCustomFont: {
      requestStatus: setStatusDefaults(),
      error: null,
    },
    addCustomFontBulk: {
      requestStatus: setStatusDefaults(),
      error: null,
    },
    removeCustomFont: {
      requestStatus: setStatusDefaults(),
    },
  },
  reducers: {
    setSelectedCustomFont: (state, { payload }) => {
      state.customFonts.selected = payload;
    },
    resetSelectedCustomFont: (state) => {
      state.customFonts.selected = null;
    },
    addRecentlyAddedCustomFont: (state, { payload }) => {
      state.customFonts.recentlyAdded = [...state.customFonts.recentlyAdded, payload];
    },
    resetRecentlyAddedCustomFonts: (state) => {
      state.customFonts.recentlyAdded = [];
    },
    setFontToRemove: (state, { payload }) => {
      state.fontToRemove = payload;
    },
    removeRecentlyAddedCustomFont: (state, { payload }) => {
      state.customFonts.recentlyAdded = state.customFonts.recentlyAdded.filter((font) => font.fontName !== payload);
    },
    resetCustomFontsErrors: (state) => {
      state.addCustomFont.error = null;
      state.addCustomFontBulk.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(typographyThunks.getFonts.pending, (state) => {
        state.getFonts.requestStatus = getRequestStatus();
      })
      .addCase(typographyThunks.getFonts.fulfilled, (state, { payload }) => {
        const { available, selected } = payload;
        const availableMappedFonts = available.map((font) => ({
          id: font.id,
          label: font.name,
          value: font.id,
        }));
        const customFonts = available.filter((font) => font.source === TYPOGRAPHY_FONT_TYPES.CUSTOM);
        const customMappedFonts = customFonts.map((font) => ({
          ...font,
          id: font.id,
          label: font.name,
          value: font.id,
        }));
        state.availableFonts = availableMappedFonts;
        state.selectedFonts = selected;
        state.customFonts.uploaded = customMappedFonts;
        state.getFonts.requestStatus = getResponseStatus();
      })
      .addCase(typographyThunks.getFonts.rejected, (state, { error }) => {
        state.getFonts.requestStatus = getErrorStatus();
      })
      .addCase(typographyThunks.updateSelectedFont.pending, (state) => {
        state.updateSelectedFont.requestStatus = getRequestStatus();
      })
      .addCase(typographyThunks.updateSelectedFont.fulfilled, (state, { payload, meta }) => {
        state.selectedFonts = {
          ...state.selectedFonts,
          [meta.arg.data.selectionType === TYPOGRAPHY_FONT_VARIANTS.HEADLINE ? "headlineId" : "regularId"]: payload.id,
        };
        state.updateSelectedFont.requestStatus = getResponseStatus();
      })
      .addCase(typographyThunks.updateSelectedFont.rejected, (state) => {
        state.updateSelectedFont.requestStatus = getErrorStatus();
      })
      .addCase(typographyThunks.resetSelectedFont.pending, (state) => {
        state.resetSelectedFont.requestStatus = getRequestStatus();
      })
      .addCase(typographyThunks.resetSelectedFont.fulfilled, (state, { payload }) => {
        const { headline, regular } = payload.typography;
        state.selectedFonts = {
          headlineId: headline.id,
          regularId: regular.id,
        };
        state.resetSelectedFont.requestStatus = getResponseStatus();
      })
      .addCase(typographyThunks.resetSelectedFont.rejected, (state) => {
        state.resetSelectedFont.requestStatus = getErrorStatus();
      })
      .addCase(typographyThunks.addCustomFont.pending, (state) => {
        state.addCustomFont.requestStatus = getRequestStatus();
        state.addCustomFont.error = null;
      })
      .addCase(typographyThunks.addCustomFont.fulfilled, (state, { payload }) => {
        state.customFonts.uploaded = [...state.customFonts.uploaded, payload];
        state.availableFonts = [
          ...state.availableFonts,
          {
            id: payload.id,
            label: payload.name,
            value: payload.id,
          },
        ];
        state.customFonts.selected = null;
        state.customFonts.recentlyAdded = [];
        state.addCustomFont.requestStatus = getResponseStatus();
      })
      .addCase(typographyThunks.addCustomFont.rejected, (state, { payload }) => {
        state.addCustomFont.requestStatus = getErrorStatus();
        if (payload.status === 409) {
          state.addCustomFont.error = payload.message?.split("for webshop")[0];
        }
      })
      .addCase(typographyThunks.addCustomFontBulk.pending, (state) => {
        state.addCustomFontBulk.requestStatus = getRequestStatus();
        state.addCustomFontBulk.error = null;
      })
      .addCase(typographyThunks.addCustomFontBulk.fulfilled, (state, { payload }) => {
        const { successes, failures } = payload;
        const mappedSuccesses = successes.map((font) => ({
          id: font.id,
          label: font.name,
          value: font.id,
        }));
        state.customFonts.uploaded = [...state.customFonts.uploaded, ...successes];
        state.availableFonts = [...state.availableFonts, ...mappedSuccesses];
        state.customFonts.recentlyAdded = [];
        state.customFonts.selected = null;
        state.addCustomFontBulk.error = failures;
        state.addCustomFontBulk.requestStatus = getResponseStatus();
      })
      .addCase(typographyThunks.addCustomFontBulk.rejected, (state) => {
        state.addCustomFontBulk.requestStatus = getErrorStatus();
      })
      .addCase(typographyThunks.removeCustomFont.pending, (state) => {
        state.removeCustomFont.requestStatus = getRequestStatus();
      })
      .addCase(typographyThunks.removeCustomFont.fulfilled, (state, { meta }) => {
        state.customFonts.uploaded = state.customFonts.uploaded.filter((font) => font.id !== meta.arg.fontId);
        state.availableFonts = state.availableFonts.filter((font) => font.id !== meta.arg.fontId);
        state.fontToRemove = null;
        state.removeCustomFont.requestStatus = getResponseStatus();
      })
      .addCase(typographyThunks.removeCustomFont.rejected, (state) => {
        state.removeCustomFont.requestStatus = getErrorStatus();
      });
  },
});

export const availableFontsSelector = (state) => state.webshops.typography.availableFonts;
export const selectedFontsSelector = (state) => state.webshops.typography.selectedFonts;
export const selectedCustomFontSelector = (state) => state.webshops.typography.customFonts.selected;
export const recentlyAddedCustomFontsSelector = (state) => state.webshops.typography.customFonts.recentlyAdded;
export const uploadedCustomFontsSelector = (state) => state.webshops.typography.customFonts.uploaded;
export const typographySelector = (state) => state.webshops.typography;

export const {
  setSelectedCustomFont,
  resetSelectedCustomFont,
  addRecentlyAddedCustomFont,
  resetRecentlyAddedCustomFonts,
  removeRecentlyAddedCustomFont,
  setFontToRemove,
  resetCustomFontsErrors,
} = typographySlice.actions;

export default typographySlice.reducer;

listenerMiddleware.startListening({
  matcher: isAnyOf(typographyThunks.updateSelectedFont.fulfilled),
  effect: async ({ payload, meta }, listenerApi) => {
    const { selectionType } = meta.arg.data;
    const typographyKey = selectionType.toLowerCase();
    const state = listenerApi.getState();
    const { typography } = state.webshops.builder.storefront.data.storefront.themeV2;
    const updatedTypography = { ...typography, [typographyKey]: payload };
    listenerApi.dispatch(updateStorefrontTypography(updatedTypography));
  },
});

listenerMiddleware.startListening({
  matcher: isAnyOf(typographyThunks.resetSelectedFont.fulfilled),
  effect: async ({ payload }, listenerApi) => {
    listenerApi.dispatch(updateStorefrontTypography(payload?.typography));
  },
});
