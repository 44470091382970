import {
  recentlyAddedCustomFontsSelector,
  typographySelector,
  typographyThunks,
} from "@redux/webshops/builder/typographySlice";
import * as Sentry from "@sentry/react";
import { generateFormWithMultipleFilePayloads } from "@src/utils/webshop";
import opentype from "opentype.js";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const getFontName = (files) => {
  return new Promise((resolve, reject) => {
    const file = files[0];
    if (!file) {
      resolve(null);
      return;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      const arrayBuffer = e.target.result;
      try {
        const font = opentype.parse(arrayBuffer);
        resolve(font.names.fontFamily.en);
      } catch (err) {
        Sentry.captureException(err);
        resolve(null);
      }
    };

    reader.onerror = function (err) {
      reject(err);
    };

    reader.readAsArrayBuffer(file);
  });
};

export function useUploadCustomFont() {
  const dispatch = useDispatch();
  const recentlyAddedCustomFonts = useSelector(recentlyAddedCustomFontsSelector);
  const { fontToRemove } = useSelector(typographySelector);
  const { id } = useParams();

  async function submitFont() {
    if (recentlyAddedCustomFonts.length >= 1) {
      const filesName = recentlyAddedCustomFonts.map((font) => ({ fileName: font.file.name, fontName: font.fontName }));
      const { requestForm } = await generateFormWithMultipleFilePayloads({
        filePropertyName: "files",
        files: recentlyAddedCustomFonts.map((font) => font.file),
        dataPropertyName: "data",
        data: {
          names: filesName,
        },
      });

      return dispatch(typographyThunks.addCustomFontBulk({ webshopId: id, data: requestForm })).unwrap();
    }
  }

  function removeFont() {
    return dispatch(typographyThunks.removeCustomFont({ webshopId: id, fontId: fontToRemove })).unwrap();
  }

  return { getFontName, submitFont, removeFont };
}
