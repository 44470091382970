import React from "react";
import { Button, HStack, Icon, IconButton, Image, Link, Text, useTheme } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  builderThunks,
  builderSelector,
  updatePreviewMode,
  updateIsMobileMode,
} from "@redux/webshops/builder/builderSlice";

// The resolution icons
import { ReactComponent as LaptopIcon } from "@assets/img/icons/common/Laptop.svg";
import { ReactComponent as SmartphoneIcon } from "@assets/img/icons/common/Smartphone.svg";
import { ReactComponent as HomeIcon } from "@assets/img/icons/common/home.svg";

import { ReactComponent as PlayIcon } from "@assets/img/icons/common/Play.svg";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { webshopsSelector } from "@redux/webshops/webshopsSlice";
import { motion } from "framer-motion";

export const Header = () => {
  const { id } = useParams();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    publish: { requestStatus },
    view: { isMobile, previewMode },
  } = useSelector(builderSelector);
  const { selectedWebshop: webshop } = useSelector(webshopsSelector);

  const { name = "", icon = "", id: webshopId = "" } = webshop || {};

  const linearGradient = `
  linear-gradient(269deg,
  ${theme.colors.brandPurple[400]} -4.84%,
  ${theme.colors.brandRed[400]} 115%)
  `;

  if (previewMode.active) return null;
  return (
    <HStack bg="dark.700" borderBottom={"1px solid transparent"} borderBottomColor={"dark.600"} px={"24px"} py="16px">
      <IconButton as={NavLink} icon={<Icon as={HomeIcon} />} to="/webshops/projects" />
      <HStack marginLeft={"16px"}>
        <Image src={icon} width={"32px"} height={"32px"} rounded={"full"} objectFit={"cover"} alt={`${name} icon`} />
        <Text size={"lg"} fontWeight={500} color={"textWhite.400"}>
          {name}
        </Text>
      </HStack>
      <HStack gap="16px">
        <Link variant={"primary"} as={NavLink} to={`/webshops/projects/${id}/settings`}>
          {({ isActive }) => <Text color={isActive ? "brandPurple.400" : "textWhite.400"}>Settings</Text>}
        </Link>
        <Link variant={"primary"} as={NavLink} to={`/webshops/projects/${id}/catalog`}>
          {({ isActive }) => <Text color={isActive ? "brandPurple.400" : "textWhite.400"}>Catalog</Text>}
        </Link>
        <Link variant={"primary"} as={NavLink} to={`/webshops/projects/${id}/builder`}>
          {({ isActive }) => <Text color={isActive ? "brandPurple.400" : "textWhite.400"}>Builder</Text>}
        </Link>
        {location.pathname.includes("builder") && (
          <HStack border="1px solid #333338" borderRadius="8px" gap="1px" padding="2px">
            <IconButton
              icon={<Icon as={LaptopIcon} />}
              minW={"32px"}
              h={"32px"}
              onClick={() => dispatch(updateIsMobileMode(false))}
              aria-label="desktop screen"
              style={{
                bg: isMobile ? "transparent" : "dark.400",
              }}
            />
            <IconButton
              icon={<Icon as={SmartphoneIcon} />}
              minW={"32px"}
              h={"32px"}
              aria-label="mobile screen"
              onClick={() => dispatch(updateIsMobileMode(true))}
              style={{
                bg: isMobile ? "dark.400" : "transparent",
              }}
            />
          </HStack>
        )}
      </HStack>
      <HStack gap={"24px"} ml="auto">
        <IconButton
          minW="32px"
          h="32px"
          icon={<Icon as={PlayIcon} />}
          onClick={() => {
            navigate(`/webshops/projects/${id}/builder`, { state: { from: location.pathname } });
            dispatch(updatePreviewMode({ active: true }));
          }}
        />
        <Button
          as={motion.button}
          borderRadius={"0.5rem"}
          height={"32px"}
          color={"black"}
          isDisabled={requestStatus.pending}
          onClick={() => dispatch(builderThunks.publishStorefront(webshopId))}
          initial={{
            backgroundSize: "400% 100%",
            backgroundPosition: "0% 0%",
            backgroundImage: linearGradient,
          }}
          whileHover={{
            backgroundPosition: ["0% 0%", "100% 0%", "0% 0%"],
            transition: { duration: 1.25, repeat: Infinity, repeatType: "reverse" },
          }}
        >
          Publish
        </Button>
      </HStack>
    </HStack>
  );
};
