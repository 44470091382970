import React, { useEffect, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";
import PropTypes from "prop-types";

import {
  Box,
  HStack,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  PopoverTrigger,
  PopoverContent,
  Popover,
  PopoverCloseButton,
  Input,
  VStack,
  Button,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { FileDrop } from "@common/upload/FileDrop";
import { ReactComponent as UploadImageIcon } from "src/assets/img/icons/common/image_upload_v2.svg";
import { ReactComponent as TrashIcon } from "src/assets/img/icons/common/trash-icon.svg";
import { Slider } from "@common/slider/Slider";

import { hexFormatter, isValidHex } from "./utils";

const DEFAULT_IMAGE = require("@assets/img/icons/checkered-default.png");

const DEFAULT_COLOR = "#FFFFFF";
export const StylePicker = ({
  target = "",
  title = "Color",
  initialColor = null,
  initialImage = "",
  initialOverlay = 0,
  // Tab for image upload
  image = false,
  // Shortcut: Only show the image tab
  imageOnly = false,
  noOverlay = false,
  // Tab of quick color options
  presets = [],
  // Tab for custom color picker
  custom = true,
  onColorChange = () => {},
  onImageChange = () => {},
  onImageUpdate = () => {},
  onImageRemove = () => {},
  ...rest
}) => {
  // Needed for the tooltip/modal link
  const id = `${target}_color_picker_tooltip_${title}`;

  const showPresets = presets && presets.length > 0 && !imageOnly;
  const showCustom = custom && !imageOnly;
  const showImage = imageOnly || image;

  // const [currentSelection, setCurrentSelection] = useState("");
  // COLOR or IMAGE
  // const [currentType, setCurrentType] = useState("color");
  // Not necessarily the selected option (color/image) but what
  // they currently are, theoretically they could switch between
  // the two (in the same style picker session)
  // ### For images we should have a different component so this is only a color picker
  const [currentColor, setCurrentColor] = useState(initialColor);

  // initialImage will almost always be a string and not a file
  // maybe always, but I'm not sure.
  // currentImage can be either a string or a file
  // eslint-disable-next-line
  const [currentImage, setCurrentImage] = useState(initialImage ?? "");
  // For display purposes, this will change the image preview after the user has selected a file
  // Initial images always come as a valid string, so we can set it as the current image
  const [currentImageURL, setCurrentImageURL] = useState(initialImage ?? "");

  const [imageOverlay, setImageOverlay] = useState(initialOverlay);

  const fileDropRef = useRef();
  const prevImage = useRef(initialImage);

  useEffect(() => {
    // Prevent the initial color from being sent
    if (!currentColor || currentColor === initialColor) return;
    if (!isValidHex(currentColor)) return;
    // Don't send the color if it's the same
    onColorChange({
      type: "COLOR",
      value: currentColor,
    });
    // eslint-disable-next-line
  }, [currentColor]);

  useEffect(() => {
    if (initialColor !== currentColor) {
      setCurrentColor(initialColor);
    }
    // eslint-disable-next-line
  }, [initialColor]);

  useEffect(() => {
    // Disable the event firing on component mount
    if (currentImage === initialImage) return;
    if (!currentImage && initialImage) {
      setCurrentImage(initialImage);
      setCurrentImageURL(initialImage);
      return;
    }
    if (currentImage && !currentImageURL) {
      setCurrentImageURL(URL.createObjectURL(currentImage));
    }
    if (typeof currentImage === "string") {
      // If the type of currentImage is a string, then it hasn't changed
      // Changes will always be a new file at the moment (until assets are implemented)
      onImageUpdate({
        file: currentImage,
        overlay: imageOverlay,
      });
      return;
    }
    // Don't send the file if it's the same
    const prevName = prevImage.current?.name;
    const currName = currentImage?.name;

    if (currName && prevName && currName === prevName) return;
    prevImage.current = currentImage;
    setCurrentImageURL(URL.createObjectURL(currentImage));

    onImageChange({
      file: currentImage,
      overlay: imageOverlay,
    });
    // eslint-disable-next-line
  }, [initialImage, currentImage, onImageChange]);

  function handleOnImageChange(event) {
    const files = event.dataTransfer?.files || event.target.files;
    if (files.length > 0) {
      setCurrentImage(files[0]);
    }
  }

  function handleImageRemove() {
    fileDropRef.current.value = "";
    prevImage.current = null;
    setCurrentImage("");
    setCurrentImageURL("");
    onImageRemove();
  }

  return (
    <Popover
      placement="left-start"
      onClose={() => {
        setCurrentImage(initialImage ?? "");
        setCurrentColor(initialColor);
        setImageOverlay(initialOverlay);
      }}
    >
      {({ isOpen }) => (
        <Box>
          <PopoverTrigger>
            <HStack
              id={id}
              justifyContent="space-between"
              cursor="pointer"
              p="12px"
              bg={isOpen ? "rgba(33, 33, 38, 0.78)" : "initial"}
              _hover={{ background: "transparents.dark" }}
              {...rest}
            >
              <Text>{title}</Text>
              {!currentColor && !currentImageURL && !imageOnly && (
                <Box width="24px" height="24px" pl="4px">
                  <Icon as={AddIcon} w="16px" h="16px" />
                </Box>
              )}
              {currentColor && !currentImageURL && !imageOnly && (
                <Box
                  width="24px"
                  height="24px"
                  borderRadius="8px"
                  backgroundColor={currentColor}
                  border="1px solid rgba(250, 250, 250, 0.20)"
                />
              )}
              {(currentImageURL || imageOnly) && (
                <Box
                  width="24px"
                  height="24px"
                  borderRadius="8px"
                  bgImage={currentImageURL ? `url(${currentImageURL})` : DEFAULT_IMAGE}
                  bgSize={"cover"}
                  bgPos={"center"}
                  pos={"relative"}
                  sx={{
                    "&:after": {
                      content: '""',
                      position: "absolute",
                      top: "0",
                      right: "0",
                      bottom: "0",
                      left: "0",
                      bg: currentColor || "#000",
                      borderRadius: "8px",
                      opacity: imageOverlay / 100,
                    },
                  }}
                />
              )}
            </HStack>
          </PopoverTrigger>
          <PopoverContent maxW={"280px"}>
            {/* Close Button */}
            <PopoverCloseButton zIndex={1} />

            {/* Tabs */}
            <Tabs>
              <TabList>
                {showPresets && <Tab>Preset</Tab>}
                {showCustom && <Tab>Custom</Tab>}
                {showImage && <Tab>Image</Tab>}
              </TabList>
              <TabPanels>
                {showPresets && (
                  <TabPanel>
                    <HStack>
                      {presets.map((color, index) => (
                        <Box
                          key={`preset_color_${index}_${color}`}
                          width="40px"
                          height="40px"
                          borderRadius="10px"
                          backgroundColor={color}
                          border={currentColor === color ? "5px solid rgba(255, 255, 255, 0.70)" : "none"}
                          cursor="pointer"
                          onClick={() => {
                            setCurrentColor(color);
                          }}
                        />
                      ))}
                    </HStack>
                  </TabPanel>
                )}
                {showCustom && (
                  <TabPanel>
                    <HexColorPicker
                      style={{ width: "100%" }}
                      color={currentColor || DEFAULT_COLOR}
                      onChange={(color) => {
                        setCurrentColor(color);
                      }}
                    />
                    <Input
                      mt="16px"
                      value={currentColor || DEFAULT_COLOR}
                      onChange={(e) => {
                        setCurrentColor(hexFormatter(e.target.value));
                      }}
                    />
                  </TabPanel>
                )}
                {showImage && (
                  <TabPanel>
                    <VStack
                      alignItems={"center"}
                      justifyContent={"center"}
                      border={currentImageURL ? "none" : "2px dashed"}
                      borderColor={"dark.100"}
                      rounded={"8px"}
                      bg="dark.400"
                      py="48px"
                      px="20px"
                      w={"full"}
                      pos={"relative"}
                      bgSize={"cover"}
                      bgImage={currentImageURL ? `url(${currentImageURL})` : null}
                      bgPos={"center"}
                    >
                      {/* Image overlay needs to be under the file input */}
                      <Box
                        pos={"absolute"}
                        top={"0"}
                        right={"0"}
                        bottom={"0"}
                        left={"0"}
                        bg={currentColor || "#000"}
                        borderRadius={"8px"}
                        opacity={imageOverlay / 100}
                      />
                      {currentImage && (
                        <Box py="16px" px="24px" my={"48px"} bg="transparents.dark" borderRadius={"8px"} zIndex={1}>
                          <Text color="textWhite.400" fontWeight={500} size="regular">
                            Change image
                          </Text>
                        </Box>
                      )}
                      {!currentImage && (
                        <VStack gap="32px" my="auto" alignItems={"center"} zIndex={1}>
                          <Icon w="62px" h="62px" my="11px" as={UploadImageIcon} />
                          <Text textAlign={"center"}>Click to upload PNG, JPEG, or drag and drop here</Text>
                        </VStack>
                      )}
                      {isOpen && (
                        <FileDrop
                          inputRef={fileDropRef}
                          onChange={(event) => handleOnImageChange(event)}
                          style={{ zIndex: 1 }}
                        />
                      )}
                    </VStack>
                    {!noOverlay && (
                      <VStack gap="8px" mt="16px" alignItems={"stretch"}>
                        <Text size="small" color="textWhite.400">
                          Overlay
                        </Text>
                        <Slider
                          value={imageOverlay}
                          onChange={(value) => {
                            setImageOverlay(value);
                          }}
                          onMouseUp={() => {
                            if (typeof currentImage === "string") {
                              onImageUpdate({
                                file: currentImage,
                                overlay: imageOverlay,
                              });
                            } else {
                              onImageChange({
                                file: currentImage,
                                overlay: imageOverlay,
                              });
                            }
                          }}
                        />
                      </VStack>
                    )}
                    <Button
                      mt="16px"
                      w="full"
                      variant={"solid"}
                      isDisabled={!currentImageURL}
                      onClick={handleImageRemove}
                    >
                      <Icon as={TrashIcon} mr="8px" mt="-2px" />
                      Remove
                    </Button>
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </PopoverContent>
        </Box>
      )}
    </Popover>
  );
};

StylePicker.propTypes = {
  target: PropTypes.string,
  title: PropTypes.string,
  initialColor: PropTypes.string,
  initialImage: PropTypes.string,
  initialOverlay: PropTypes.number,
  image: PropTypes.bool,
  imageOnly: PropTypes.bool,
  noOverlay: PropTypes.bool,
  presets: PropTypes.array,
  custom: PropTypes.bool,
  onColorChange: PropTypes.func,
  onImageChange: PropTypes.func,
  onImageUpdate: PropTypes.func,
  onImageRemove: PropTypes.func,
};
